
import Vue from "vue";

export default Vue.extend({
  name: "MentorcastPasswordModal",
  props: {
    mentorcast: Object
  },
  data() {
    return {
      loading: false,
      data: {
        password: ""
      },
      errors: {} as any
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;

      this.$store
        .dispatch("mentorcast/POST_INVITATION_PASSWORD", {
          id: this.mentorcast.id,
          password: this.data.password
        })
        .then(() => {
          this.loading = false;
          this.$emit("setAccess", true);
        })
        .catch(error => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    close() {
      this.$emit("setAccess", false);
    }
  }
});
